import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/scss/main.scss'
import './plugins/clipboard'
import './plugins/animate'
import './filters/formatDate'
import './filters/currency'
import './guards/permissions'
import './guards/views'

//? Components
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from 'vee-validate'
import 'vue2-datepicker/index.css';
import { email, min_value, required } from 'vee-validate/dist/rules'

Vue.component('validation-provider',ValidationProvider)
Vue.component('validation-observer',ValidationObserver)

extend("min_value", {
  ...min_value,
  message: (field: string, params: Record<string, string | number>) => {
    return `Este campo debe ser mayor a ${params.min}`
  }
})
extend("email", {
  ...email,
  message: 'Este campo es incorrecto'
});
extend("required", {
  ...required,
  message: "Este campo es requerido",
});
setInteractionMode("eager");

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
