import { STATUS_TRIP } from "../interfaces/trips";
import { MainModule } from "@/store/modules/Main";
import { ErrorResponse } from "./request";
import { AuthModule } from "@/store/modules/Auth";
import { IPermissionGroupProfile } from "@/interfaces/profile";
import { translations } from "@/translations/app";
import { STATUS_SCHEDULE_MAINTENANCE } from "@/types/scheduleMaintenance";
/* eslint-disable */
export function getStatusTrip(status: STATUS_TRIP) {
  const statusTrip: Record<STATUS_TRIP, string> = {
    CANCELLED: "Cancelado",
    DELETED: "Eliminado",
    FINISHED: "Finalizado",
    PENDING: "Pendiente",
  };
  return statusTrip[status];
}

interface IStatusValue {
  color: string;
  value: string;
}

export const VALUES_STATUS_TRIP: Record<STATUS_TRIP, IStatusValue> = {
  CANCELLED: {
    color: "red",
    value: "Cancelado",
  },
  DELETED: {
    color: "red",
    value: "Eliminado",
  },
  FINISHED: {
    value: "Finalizado",
    color: "green darken-3",
  },
  PENDING: {
    value: "Pendiente",
    color: "orange darken-2",
  },
};

export const getErrorCatch = (
  error: ErrorResponse | any,
  sendMessage = true
) => {
  let message = error.message || "Ocurrio un error";
  let errorCode = 401;
  if (error instanceof ErrorResponse) {
    message = error.message;
    errorCode = error.statusCode;
  }
  if (sendMessage) {
    MainModule.setNotification({
      isOpen: true,
      message,
      color: "red darken-4",
    });
  }
  return {
    message,
    errorCode,
  };
};

export function fixedKm(value?: number) {
  if (value) {
    const divider = Math.pow(10, 2);
    return Math.round((value + Number.EPSILON) * divider) / divider;
  }
}

export function validateNumber(evt: KeyboardEvent) {
  if (evt.key !== '.' && evt.key !== 'Backspace' && evt.key !== "Tab" && evt.key !== 'Escape') {
    const parsetKey = parseFloat(evt.key);
    if (isNaN(parsetKey)) evt.preventDefault();
  }
}

export function setPermissions(pageName:string){
  if (AuthModule.currentUser && AuthModule.currentUser.profile) {
    const permissions = AuthModule.currentUser.profile.permissions as IPermissionGroupProfile[];
    const tripsPermission = permissions.find(({ page }) => page === pageName)
    if (tripsPermission) {
      return tripsPermission.actions
    }
  }
  return null
}

export function getStatusSchedule (status : STATUS_SCHEDULE_MAINTENANCE){
  return translations.statusMaintenance[status] || ''
}