
import { Component, Vue } from "vue-property-decorator";
import MainMenu from "../Menus/MainMenu.vue";
import Logo from "@/assets/images/icon_large.jpeg";
import { MainModule } from "../../store/modules/Main";
import { AuthModule } from "../../store/modules/Auth";

@Component({
  name: "NavigationDrawer",
  components: {
    MainMenu,
  },
})
export default class NavigationDrawer extends Vue {
  public logo = Logo
  get drawer() {
    return MainModule.isOpenDrawer;
  }

  set drawer(value: boolean) {
    MainModule.setDrawer(value);
  }

  signOut(){
    AuthModule.logout();
    location.reload();
  }
}
