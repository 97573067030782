export enum ROUTES_GLOBAL {
 TRIP = "trip",
 USERS = "user/",
 VEHICLE = "vehicle/",
 ROUTE = "route",
 AUTH = "auth/",
 PARAMETER = "parameter/",
 SETTLEMENT = "settlement/",
 BILLING = "billing/",
 PROFILE = "profile/",
 PERMISSIONS = "permissions/",
 PARTS_VEHICLE = "parts/",
 SCHEDULE_MAINTENANCE = "schedule-maintenance",
 MAINTENANCES = "maintenances",
 BILLS = 'bills/',
 INCOME = 'income/'
}
